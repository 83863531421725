/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { SetStateAction, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { theme } from './GlobalStyles';
import { navMenu } from './navMenu';
import { ButtonPrimary } from './StyledComponents';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Icon } from '@/Icon';

interface Props {
    active: boolean;
    menuName?: string | null;
}

const MobileNav = styled.button<Props>`
    height: 70px;
    box-shadow: ${({ active }) => (active ? 'none' : 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px')};
    display: flex;
    transition: box-shadow 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    background: ${({ active }) => (active ? 'none' : `${theme.colors.gray2}`)};
    z-index: 10;
    width: 100vw;
    padding: 16px 16px;
    border: none;
    .logo {
        width: 213px;
    }

    @media ${theme.device.laptop} {
        display: none;
        margin: 15px 32px;

        .logo {
            display: none;
        }
    }
`;

const MobileMenu = styled.div<Props>`
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 5;
    height: 100vh;
    background: ${theme.colors.gray2};
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 0px;
    position: fixed;
    align-items: center;
    transition: transform 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
    transform: ${({ active }) => (active ? 'translateX(0%)' : 'translateX(100%)')};
    ul {
        position: absolute;
        flex-shrink: 0;
        top: ${({ active, menuName }) => (active && menuName !== 'Services' ? '300px' : '90px')};
        list-style: none;
        left: 80px;
        svg {
            position: absolute;
            right: -90px;
            width: 10px;
            fill: ${theme.colors.white};
        }
        button {
            font-family: Poppins;
            color: ${theme.colors.white};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 162%;
            letter-spacing: 1.4px;
            margin-bottom: 40px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
        }
    }
    li {
        margin-bottom: 40px;
        a {
            text-transform: uppercase;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 162%;
            color: ${theme.colors.white};
        }
    }
    button {
        background: none;
        border: none;
    }
    .menu-selected-name {
        position: absolute;
        bottom: 200px;
        width: fit-content;
        left: 0px;
        min-width: 200px;
        display: ${({ active, menuName }) =>
            active && menuName !== 'Services' ? 'inline-block' : 'none'};
        p {
            text-decoration: underline;
            font-family: Poppins;
            color: ${theme.colors.white};
            font-size: 14px;
            font-style: normal;

            font-weight: 400;
            line-height: 162%;
            letter-spacing: 1.4px;
        }
        svg {
            margin-right: 8px;
            fill: ${theme.colors.white};
            transform: rotate(90deg);
        }
    }
    @media ${theme.device.laptop} {
        display: none;
    }
`;

const StyledNav = styled.nav`
    width: 10vw;
`;

const Burger = styled.div<Props>`
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: end;
    border: none;
    gap: 6px;
    background: none;
    margin-left: 32px;
    div {
        border-radius: 15px;
        height: 3px;
        background: ${theme.colors.white};
    }
    .line-one {
        transition: 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
        position: relative;
        top: ${({ active }) => (!active ? '0' : '6px')};
        transform: ${({ active }) => (!active ? 'rotate(0%)' : 'rotate(45deg)')};
        width: ${({ active }) => (!active ? '24px' : '24px')};
    }
    .line-two {
        width: ${({ active }) => (!active ? '12px' : '0px')};
        transition: 0.5s cubic-bezier(0.1, 0.7, 0.6, 0.9);
    }
    .line-three {
        transition: 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
        position: relative;
        bottom: ${({ active }) => (!active ? '0' : '12px')};
        transform: ${({ active }) => (!active ? 'rotate(0%)' : 'rotate(-45deg)')};
        width: ${({ active }) => (!active ? '24px' : '24px')};
    }
    @media ${theme.device.laptop} {
        display: none;
    }
`;

interface NewProps {
    active: boolean;
}

const DesktopNav = styled.div<NewProps>`
    display: none;
    .logo {
        display: none;
    }
    @media ${theme.device.laptop} {
        display: flex;
        transition: box-shadow 0.2s ease-in;
        transition: background-color 0.2s ease-in;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background: ${theme.colors.gray2};
        align-items: center;
        position: fixed;
        top: 0;
        width: 100vw;
        justify-content: space-between;
        z-index: 4;
        margin: 0px 0px 0px;
        padding: 16px 32px;
        .main-menu {
            display: flex;
            gap: 36px;
            position: relative;
            justify-content: space-between;
            list-style: none;

            > li {
                cursor: pointer;
                display: flex;
                align-items: center;
                transition: 0.2s ease-in;
                a {
                    text-decoration: none;
                    color: ${theme.colors.white};
                }
                svg {
                    position: relative;
                    bottom: 2px;
                    height: 7px;
                    fill: ${theme.colors.white};
                    margin-left: 10px;
                    transition: 0.2s ease-in;
                }
                &:hover {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            > li {
                display: inline-block;
                position: relative;
            }
            > li:hover:after {
                transform: scaleX(1);
                transform-origin: left right;
            }
            > li:after {
                content: '';
                position: absolute;
                width: 50%;
                transform: scaleX(0);
                height: 2px;
                bottom: -3px;
                left: 24.9%;
                background-color: ${theme.colors.red1};
                transform-origin: left right;
                transition: transform 0.25s cubic-bezier(0.33, 1, 0.68, 1);
            }
        }

        .desktop-buttons {
            display: flex;
            align-items: stretch;

            button {
                margin-right: 20px;
            }
        }
        .logo-desktop {
            justify-self: end;
            display: block;
            width: 347px;
        }
    }
    @media ${theme.device.laptopL} {
        .main-menu {
            right: 120px;
        }
        .desktop-buttons {
            display: flex;
            button {
                margin-right: 20px;
            }
        }
    }
    @media ${theme.device.desktop} {
        .main-menu {
            right: 180px;
        }
    }
`;

const DesktopMenu = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: ${theme.colors.gray1};
    min-width: 303px;
    opacity: 1;
    z-index: 2;
    box-shadow:
        0px 51px 125px rgba(109, 106, 93, 0.09),
        0px 18.5323px 39.5627px rgba(109, 106, 93, 0.0393285),
        0px 8.27833px 15.9688px rgba(109, 106, 93, 0.0425114),
        0px 4.0063px 6.7726px rgba(109, 106, 93, 0.0304631);
    border-radius: 15px;
    list-style: none;
    left: -16px;
    top: 50px;
    a {
        padding: 0px 70px 0 0;

        text-decoration: none;
    }
    li {
        transition: 0.2s ease-in;
        z-index: 0;
        padding: 12px 0 12px 12px;
        cursor: pointer;
        position: relative;
        &:hover {
            background: ${theme.colors.red1};
        }
    }
    li:first-of-type {
        border-radius: 15px 15px 0 0;
        padding-top: 16px;
        cursor: pointer;
    }
    li:last-of-type {
        border-radius: 0px 0px 15px 15px;
        padding-bottom: 16px;
        cursor: pointer;
    }
    &:hover {
        opacity: 1;
    }
`;

const DesktopSubMenu = styled.ul`
    background: ${theme.colors.gray1};
    display: flex;
    position: absolute;
    flex-direction: column;
    background: ${theme.colors.gray1};
    opacity: 1;
    z-index: 3;
    box-shadow:
        0px 51px 125px rgba(109, 106, 93, 0.09),
        0px 18.5323px 39.5627px rgba(109, 106, 93, 0.0393285),
        0px 8.27833px 15.9688px rgba(109, 106, 93, 0.0425114),
        0px 4.0063px 6.7726px rgba(109, 106, 93, 0.0304631);
    border-radius: 15px;
    list-style: none;
    right: -244px;
    top: 50px;
    width: 309px;
    a {
        padding: 0px 70px 0 0;
        color: black;
        text-decoration: none;
    }

    li {
        transition: 0.2s ease-in;
        z-index: 0;
        padding: 12px 0 12px 12px;
        cursor: pointer;
        position: relative;
        &:hover {
            background: ${theme.colors.red1};
        }
    }
    li:first-of-type {
        border-radius: 15px 15px 0 0;
        padding-top: 16px;
        cursor: pointer;
    }
    li:last-of-type {
        border-radius: 0px 0px 15px 15px;
        padding-bottom: 16px;
        cursor: pointer;
    }
    &:hover {
        opacity: 1;
    }
`;

const DesktopTopSubMenu = styled(DesktopSubMenu)`
    top: 95px;
`;

type MenuType = {
    name: string;
    path: string;
};

export const Nav = () => {
    const [active, setActive] = useState(false);
    const [subActive, setSubActive] = useState(false);
    const [menuName, setMenuName] = useState<string | null>(null);
    const [menuState, setMenuState] = useState<MenuType[] | null>(null);
    const [subMenuName, setSubMenuName] = useState<string | null>(null);
    const [subMenuState, setSubMenuState] = useState<MenuType[] | null>(null);
    const navRef = useRef(null);

    const handleNavClick = (e: { target: React.MutableRefObject<null> } | any) => {
        e.target !== navRef.current ? setActive(false) : setActive(true);
        e.target !== navRef.current ? setSubActive(false) : setSubActive(true);
    };

    return (
        <StyledNav onMouseLeave={handleNavClick} ref={navRef}>
            <MobileNav active={active} className="">
                <>
                    {!subActive && !menuName && !menuState ? (
                        <Link
                            onClick={() => setSubMenuName('')}
                            aria-label="First Care Logo"
                            to="/"
                        >
                            <Icon name="logo" className="logo" />
                        </Link>
                    ) : (
                        <button
                            onClick={() => {
                                setMenuState(null);
                                setMenuName('');
                            }}
                        >
                            <Icon name="back-arrow" />
                        </button>
                    )}

                    <Burger
                        onClick={() => {
                            setActive(active => !active);
                        }}
                        active={active}
                    >
                        <div className="line-one"></div>
                        <div className="line-two"></div>
                        <div className="line-three"></div>
                    </Burger>
                </>
            </MobileNav>
            <MobileMenu menuName={menuName} active={active}>
                <ul>
                    {!menuState &&
                        navMenu.map(({ menu, name }) => {
                            if (menu) {
                                return (
                                    <li key={name}>
                                        <button
                                            onClick={() => {
                                                setMenuName(name);
                                                setMenuState(menu);
                                            }}
                                        >
                                            {name}
                                            <Icon name="arrow-nav" />
                                        </button>
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={name}>
                                        <button
                                            onClick={() => {
                                                setMenuName(name);
                                                setMenuState(menu);
                                            }}
                                        >
                                            {name}
                                        </button>
                                    </li>
                                );
                            }
                        })}
                </ul>
                {
                    <ul>
                        {menuState && (
                            <button className="menu-selected-name">
                                <p>{menuName}</p>
                            </button>
                        )}
                        {menuState &&
                            menuName &&
                            menuState.map(subItems => {
                                return (
                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                    <li
                                        onClick={() => {
                                            setActive(false);
                                            setSubActive(false);
                                            setMenuState(null);
                                            setMenuName('');
                                        }}
                                        key={subItems.name}
                                    >
                                        <Link to={subItems.path}>{subItems.name}</Link>
                                    </li>
                                );
                            })}
                    </ul>
                }
            </MobileMenu>
            <DesktopNav className="" active={active}>
                <Link to="/">
                    <StaticImage
                        alt="first-care logo"
                        src="../../images/common/logo.png"
                        placeholder="none"
                        quality={100}
                        className="logo-desktop"
                    />
                </Link>
                <ul className="main-menu">
                    {navMenu.map(({ menu, name, path }) => {
                        if (menu) {
                            return (
                                <li
                                    onMouseEnter={() => {
                                        setActive(true);
                                        setSubActive(false);
                                        setMenuName(name);
                                        setMenuState(menu);
                                        setSubMenuName(null);
                                        setSubMenuState(null);
                                        setSubActive(false);
                                    }}
                                    key={name}
                                >
                                    <Link onClick={() => setActive(false)} to={path}>
                                        {name}
                                        <Icon name="arrow-nav-desktop" />
                                    </Link>
                                    {
                                        <DesktopMenu>
                                            {active &&
                                                menuName === name &&
                                                menuState &&
                                                menuState.map(
                                                    (subItems: {
                                                        name: string;
                                                        subMenu?: SetStateAction<MenuType[] | null>;
                                                        path: string;
                                                        externalPath?: string;
                                                    }) => {
                                                        if (!subItems.subMenu) {
                                                            return (
                                                                <li key={subItems.name}>
                                                                    {subItems.path ? (
                                                                        <Link
                                                                            style={{
                                                                                color: `${theme.colors.white}`,
                                                                            }}
                                                                            onClick={() => {
                                                                                setActive(false);
                                                                                setSubMenuName(
                                                                                    null
                                                                                );
                                                                                setSubMenuState(
                                                                                    null
                                                                                );
                                                                                setSubActive(false);
                                                                            }}
                                                                            to={subItems.path}
                                                                        >
                                                                            {subItems.name}
                                                                        </Link>
                                                                    ) : (
                                                                        <a
                                                                            href={
                                                                                subItems.externalPath
                                                                            }
                                                                            style={{
                                                                                color: `${theme.colors.white}`,
                                                                            }}
                                                                            rel="noopener noreferrer"
                                                                            target="__blank"
                                                                        >
                                                                            {subItems.name}
                                                                        </a>
                                                                    )}
                                                                </li>
                                                            );
                                                        } else {
                                                            return (
                                                                <li key={subItems.name}>
                                                                    {subItems.path ? (
                                                                        <Link
                                                                            onMouseEnter={() => {
                                                                                if (
                                                                                    subItems.subMenu
                                                                                ) {
                                                                                    setSubMenuState(
                                                                                        subItems.subMenu
                                                                                    );
                                                                                }
                                                                                setActive(true);
                                                                                setSubMenuName(
                                                                                    subItems.name
                                                                                );
                                                                                setSubActive(true);
                                                                            }}
                                                                            style={{
                                                                                color: `${theme.colors.white}`,
                                                                            }}
                                                                            to={subItems.path}
                                                                            key={name}
                                                                        >
                                                                            {subItems.name}
                                                                        </Link>
                                                                    ) : (
                                                                        <a
                                                                            href={
                                                                                subItems.externalPath
                                                                            }
                                                                            style={{
                                                                                color: `${theme.colors.white}`,
                                                                            }}
                                                                            rel="noopener noreferrer"
                                                                            target="__blank"
                                                                        >
                                                                            {subItems.name}
                                                                        </a>
                                                                    )}
                                                                </li>
                                                            );
                                                        }
                                                    }
                                                )}
                                        </DesktopMenu>
                                    }
                                </li>
                            );
                        } else {
                            return (
                                <Link key={name} to={path}>
                                    {name}
                                </Link>
                            );
                        }
                    })}
                    {subActive && subMenuState && subMenuName === 'Cosmetic Dentistry' && (
                        <DesktopTopSubMenu>
                            {subMenuState.map(item => {
                                return (
                                    <li key={item.name}>
                                        <Link
                                            style={{
                                                color: `${theme.colors.white}`,
                                            }}
                                            to={item.path}
                                        >
                                            {item.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </DesktopTopSubMenu>
                    )}
                    {subActive && subMenuState && subMenuName === 'General & Family Dentistry' && (
                        <DesktopSubMenu>
                            {subMenuState.map(item => {
                                return (
                                    <li key={item.name}>
                                        <Link
                                            style={{
                                                color: `${theme.colors.white}`,
                                            }}
                                            to={item.path}
                                        >
                                            {item.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </DesktopSubMenu>
                    )}
                </ul>
                <div className="desktop-buttons">
                    <Link to="#contact">
                        <ButtonPrimary solid color="red">
                            Book Now
                        </ButtonPrimary>
                    </Link>
                </div>
            </DesktopNav>
        </StyledNav>
    );
};
